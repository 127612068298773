<template>
  <div
    class="bin-coupons-block"
    :class="{ 'not-last': isShowTxt && isPayBinCouponsAdd }"
  >
    <div
      v-if="isShowTxt"
      v-expose="{
        id: '1-11-1-219',
        data: binCouponType
      }"
      class="pay-bin-coupons"
      @click="goCouponPage"
    >
      <span
        class="txt txt-discount"
        :class="{ 'txt-normal-discount': normalPayData.showCard }"
      >
        {{ discountText }}
      </span>
      <span
        v-if="normalPayData.showCard"
        class="txt txt-normal-pay"
      >
        {{ normalPayData.cardName }}
      </span>
      <span
        v-if="!isUseCoupon"
        class="txt"
        :class="{ 'txt-small': normalPayData.showCard }"
      >
        &gt;
      </span>
    </div>
    <span
      v-if="isShowTxt && isPayBinCouponsAdd"
      class="add-tip"
    >
      +
    </span>
  </div>
</template>

<script setup>
/**
 * @file 支付方式组件: 卡bin优惠券入口组件
 */
import { computed } from 'vue'
import {
  useStore,
  useMapMutations
} from 'public/src/pages/checkout/hooks/index.js'
import { useCouponsDiscount } from './useCouponsDiscount.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'

const props = defineProps({
  scene: {
    type: String,
    default: ''
  },
  language: {
    type: Object,
    default: () => {}
  },
  cardBin: {
    type: String,
    default: ''
  },
  isPayBinCouponsAdd: {
    type: [Boolean, Number],
    default: false
  },
  isShowTokenPayment: {
    type: Boolean,
    default: false
  },
  payCode: {
    type: String,
    default: ''
  }
})

const store = useStore()
const { changeParamsStatus, showCouponListAndAnchor } = useMapMutations([
  'changeParamsStatus',
  'showCouponListAndAnchor'
])

const usableCouponList = computed(() => {
  return store?.state?.checkout?.usableCouponList
})

const coupon = computed(() => {
  return store?.state?.checkout?.coupon
})

const supportBinData = computed(() => {
  if (props.isShowTokenPayment) {
    const nowUseCouponData = usableCouponList.value?.find(item =>
      item?.card?.card_codes?.includes(props.cardBin)
    )

    return nowUseCouponData
  } else {
    const nowUseCoupon = usableCouponList.value?.find(item => {
      return (
        coupon.value?.applyCoupons?.includes(item?.coupon) &&
        item?.card?.card_name && // 卡 bin 优惠券
        (item?.payment_limit?.length === 0 || item?.payment_limit?.includes(props.payCode)) // 有支付方式限制
      )
    })

    // 已若卡bin优惠券券已被使用（计算价格使用了券码），则正常下发展示使用成功的卡bin优惠券信息
    if (nowUseCoupon) {
      return nowUseCoupon
    }
    // 非token支付情况下，选择第一个有cardName的券
    const curCoupon = usableCouponList.value?.find(
      item => item?.card?.card_name && (item?.payment_limit?.length === 0 || item?.payment_limit?.includes(props.payCode))
    )
    return curCoupon
  }
})

const isShowTxt = computed(() => {
  return !!supportBinData.value
})

const discount = computed(() => {
  const couponTypeId = supportBinData.value?.coupon_type_id

  const { getDiscount, getCurrentCouponRule } = useCouponsDiscount(
    props.language
  )

  return getDiscount(couponTypeId, getCurrentCouponRule(supportBinData.value))
})

const isUseCoupon = computed(() => {
  return (
    !!coupon.value &&
    !!supportBinData.value &&
    coupon.value?.applyCoupons?.includes(supportBinData.value?.coupon)
  )
})

const isInDrawerList = computed(() => {
  // return isShowBinCouponsDrawerInNormalPay.value
  return store?.state?.checkout.isShowBinCouponsDrawerInNormalPay
})

const discountText = computed(() => {
  return isUseCoupon.value
    ? `${template(discount.value, props.language.SHEIN_KEY_PWA_31382)}`
    : `${template(discount.value, props.language.SHEIN_KEY_PWA_31381)}`
})

// 在非token支付的情况下，是否展示card 和  cardName取值
const normalPayData = computed(() => {
  let data = {
    showCard: false,
    cardName: ''
  }

  if (!props.isShowTokenPayment && supportBinData.value) {
    data.showCard = true
    data.cardName = supportBinData.value?.card?.card_name
  }
  return data
})

const binCouponType = computed(() => {
  return {
    bincoupon_type: isUseCoupon.value ? 1 : 0,
    payment_method: props.payCode
  }
})

const goCouponPage = () => {
  daEventCenter.triggerNotice({
    daId: '1-11-1-220',
    extraData: {
      ...binCouponType.value
    }
  })
  if (isInDrawerList.value) return // 非token切换支付方式抽屉展示时，点击不在支持跳转券列表
  if (isUseCoupon.value) return // 用券后, 点击不在支持跳转券列表
  showCouponListAndAnchor(supportBinData.value?.coupon)
  changeParamsStatus({
    type: 'pageStatus',
    params: { chooseCouponDrawer: true }
  })
}
</script>

<style scoped lang="less">
.bin-coupons-block {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding-bottom: 0.08rem;
  margin-bottom: 0.08rem;
  .pay-bin-coupons {
    display: flex;
    align-items: center;
    margin-bottom: unset;
    border: 1/75rem #ffe2cf solid;
    border-radius: 4/75rem;
    background-color: #fff6f3;
    padding: 0.05333333rem 15/75rem;
    position: relative;
    color: var(---sui_color_discount_dark, #c44a01);
    font-size: 10/37.5rem;
    font-family: SF UI Text;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 1;
    max-width: 100%;

    // 超出一行展示省略号
    .txt {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .txt-discount {
      max-width: 96%;
      margin-right: 4/37.5rem;
    }
    .txt-discount.txt-normal-discount {
      max-width: 220/37.5rem;
    }
    .txt-normal-pay {
      flex: 1;
      position: relative;
      padding-left: 4/37.5rem;
      margin-right: 4/37.5rem;
      font-weight: 400;
      &::before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 20%;
        left: 0;
        width: 1/75rem;
        height: 70%;
        border-left: 1/75rem solid var(---sui_color_discount_dark, #c44a01);
      }
    }
    .txt-small {
      font-weight: 400;
    }
  }

  .add-tip {
    margin: 0 8/75rem;
  }
}
.bin-coupons-block.not-last {
  .pay-bin-coupons{
    max-width: 96%;
  }
}
.payment-options-item__coupons_txt.not-last{
  margin-bottom: -0.05rem;
}
.pay-item-token__bin_coupons.not-last {
  margin-bottom: unset;
}
.discount-tips__coupons_txt {
  margin-bottom: 0;
}

.coupons-before-after() {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 50%;
  width: 7/75rem;
  height: 14/75rem;
  border: 1/75rem solid #ffe2cf;
  transform: translateY(-50%);
  background-color: white;
}

.pay-bin-coupons::before {
  .coupons-before-after();
  left: -2/75rem;
  border-left-color: #fff;
  border-radius: 0 7/75rem 7/75rem 0;
}

.pay-bin-coupons::after {
  .coupons-before-after();
  right: -2/75rem;
  border-right-color: #fff;
  border-radius: 7/75rem 0 0 7/75rem;
}

</style>
