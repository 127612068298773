import { computed } from 'vue'
import { template } from '@shein/common-function'
import { CO_BRAND_CARD_LOGO } from '../page_tpls/token_pay/constant'
import { isPaymentForCoBrandedCard, transformCoBrandCardRights } from '../components/tools'

/** 支付方式维度或者卡token维度的联名卡权益展示，token维度需要检查联名卡标识
 * @param {Object} store - vuex store
 * @param {Object} paymentCode - 支付方式code
 * @param {Object} language - 语言包
 * @param {Object} options.isToken - 是否token，是则需要校验卡bin
 * @param {Object} options.coBrandCardTag - 联名卡标签
 */
export default (
  store,
  paymentCode,
  language,
  { isToken, coBrandCardTag } = {}
) => {
  const checkout = computed(() => store.state?.checkout || {})

  // 最终展示的积分数值，站点或者支付方式不支持的返回0
  const pointsWithSupport = computed(() => {
    const points = store.getters['checkout/pointsFromCoBrandCard']
    if (points === 0) return 0

    const isSupportPayment = isPaymentForCoBrandedCard(
      paymentCode.value,
      checkout.value?.checkout?.CAN_USE_CO_BRANDED_OF_CARDINSTALLMENT
    )
    const isSupportToken = (isToken && coBrandCardTag === 1) || !isToken
    if (!isSupportPayment || !isSupportToken) return 0

    return points
  })

  // 支付方式下方联名卡权益展示内容
  const coBrandedCardBenefits = computed(() => {
    const coBrandQualificationInfo = checkout.value?.coBrandQualificationInfo
    return transformCoBrandCardRights(pointsWithSupport.value, coBrandQualificationInfo, language)
  })

  // 支付方式下方联名卡权益展示内容
  const coBrandedCardBenefitsForToken = computed(() => {
    return pointsWithSupport.value
      ? template(pointsWithSupport.value, language.SHEIN_KEY_PWA_34931)
      : null
  })

  return {
    CO_BRAND_CARD_LOGO,
    coBrandedCardBenefits,
    coBrandedCardBenefitsForToken
  }
}
