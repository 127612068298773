<template>
  <div class="all-discount">
    <!-- 卡bin优惠券 -->
    <pay-bin-coupons
      v-if="isCanShowPayBinCoupons"
      :card-bin="cardBin"
      :is-pay-bin-coupons-add="isPayBinCouponsAdd"
      :is-show-token-payment="isCouponsShowTokenPayment"
      :language="language"
      :pay-code="item.code"
    />

    <!-- token 卡 bin 优惠 -->
    <div
      v-if="isShowTokenBinDiscountBlock && isNoShowTokenBinRandomDiscount"
      class="token-bin-block"
    >
      <span class="discount-tips-item">
        {{ tokenBinDiscountDesc }}
      </span>
      <span
        v-if="isHadShowOnlinePayDiscount || isShowRandomDiscount"
        class="add-tip"
      >
        +
      </span>
    </div>

    <div
      class="discount-percent_tips discount-percent-sy"
      :class="[
        {
          'had-token-bin': isShowTokenBinDiscountBlock
        }
      ]"
    >
      <div
        v-if="
          isShowOnlinePayDiscount &&
            onlinePayDiscountInfoValue &&
            onlinePayDiscountInfoValue.discountType != 2
        "
        class="discount-tips-item"
      >
        <div
          v-if="onlinePayDiscountInfoMinAmount != 0"
          class="payment-discount-tip"
          :style="{ color: IS_RW && '#FC4070' }"
        >
          <!-- 满减优惠 -->
          <template v-if="onlinePayDiscountInfoValue.discountType == 0">
            {{
              template(
                onlinePayDiscountInfoValue.min.amountWithSymbol,
                onlinePayDiscountInfoValue.amount.amountWithSymbol,
                language.SHEIN_KEY_PWA_16207
              )
            }}
          </template>
          <!-- 折扣优惠 -->
          <template v-if="onlinePayDiscountInfoValue.discountType == 1">
            {{
              template(
                onlinePayDiscountInfoValue.min.amountWithSymbol,
                onlinePayDiscountInfoValue.percent + '%OFF',
                language.SHEIN_KEY_PWA_16207
              )
            }}.
          </template>
          <!-- 最大优惠信息 -->
          <template
            v-if="
              onlinePayDiscountInfoValue.discountType == 1 &&
                onlinePayDiscountInfoValue.limit_value &&
                +onlinePayDiscountInfoValue.limit_value.amount != 0
            "
          >
            {{
              language.SHEIN_KEY_PWA_19681.replace(
                '{limitValue}',
                onlinePayDiscountInfoValue.limit_value.amountWithSymbol
              )
            }}
          </template>
        </div>
        <div
          v-else
          class="payment-discount-tip"
          :style="{ color: IS_RW && '#FC4070' }"
        >
          <!-- 最大优惠信息 -->
          <template
            v-if="
              onlinePayDiscountInfoValue.discountType == 1 &&
                onlinePayDiscountInfoValue.limit_value &&
                +onlinePayDiscountInfoValue.limit_value.amount != 0
            "
          >
            {{
              language.SHEIN_KEY_PWA_19681.replace(
                '{limitValue}',
                onlinePayDiscountInfoValue.limit_value.amountWithSymbol
              )
            }}
          </template>
        </div>
      </div>

      <!-- 随机立减 -->
      <div
        v-if="isShowRandomDiscountDes"
        class="discount-tips-item"
      >
        <div
          class="payment-discount-tip"
          :style="{ color: IS_RW && '#FC4070' }"
        >
          {{ randomDiscountDes }}
        </div>
      </div>
      <!-- 卡bin优惠信息展示 -->
      <template v-if="!isShowTokenPayment && !isShowTokenFrontInstallment">
        <span
          v-if="
            isShowOnlinePayDiscount &&
              isShowCardBinDiscount &&
              cardBinDiscountDesc
          "
          class="add-icon"
        >
          +
        </span>
        <span
          v-if="isShowCardBinDiscountDesc"
          class="discount-tips-item"
        >
          <span ref="cardBinDiscountDescRef">{{ cardBinDiscountDesc }}</span>
          <i
            ref="cardBinDiscountTipRef"
            class="sui_icon_doubt_16px_2 icon"
            :class="[
              paymentIconPrefix,
              {
                'tip-wrapped': cardBinDiscountTipRefIsWrapped,
                'no-left': isTextWrapped
              }
            ]"
            style="width: 12px"
            @click.stop="handlePaymentOptionsOper('bin-discount-article')"
          ></i>
        </span>
      </template>
    </div>
    <div
      v-if="coBrandedCardBenefits"
      class="co-branded-card-benefits-wrap"
    >
      <span
        v-if="showCoBrandAdd"
        class="add-tip"
      >+</span>
      <span class="discount-tips-item">
        <span v-html="coBrandedCardBenefits"></span>
        <i
          class="sui_icon_doubt_16px_2 icon"
          :class="[paymentIconPrefix]"
          style="width: 12px"
          @click.stop="handlePaymentOptionsOper('co-brand-card-article')"
        ></i></span> 
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, ref, watch } from 'vue'
import { template } from '@shein/common-function'
import { useDiscount } from './useDiscount'
import { useStore } from 'public/src/pages/checkout/hooks/index.js'
import { useTokenBinDiscount } from './useTokenBinDiscount.js'
import useCoBrandedCardPaymentToken from '../../hooks/useCoBrandedCardPaymentToken'
import PayBinCoupons from 'public/src/pages/checkout/page_tpls/coupons/pay_bin_coupons.vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => {}
  },
  cardBin: {
    type: String,
    default: ''
  },
  coBrandCardTag: {
    type: Number,
    default: 0
  }
})

const store = useStore()

const cardBinDiscountTipRef = ref(null)
const cardBinDiscountTipRefIsWrapped = ref(null)
const isTextWrapped = ref(false)
const cardBinDiscountDescRef = ref(null)
const tokenBinDiscountDesc = ref('')
const isNowTokenBinRandomType = ref(false)

const emits = defineEmits(['payment-options-oper'])

const {
  onlinePayDiscountInfoValue,
  cardBinDiscountDesc,
  isShowRandomDiscountDes,
  isShowOnlinePayDiscount,
  isShowCardBinDiscount,
  randomDiscountAbt
} = useDiscount({
  initialPayment: props.item,
  language: props.language,
  isUseInitialPayment: true
})

const paymentCode = computed(() => props.item.code)
const { coBrandedCardBenefits } = useCoBrandedCardPaymentToken(store, paymentCode,  props.language, {
  isToken: true,
  coBrandCardTag: props.coBrandCardTag
})

const allCheckout = computed(() => {
  return store?.state?.checkout
})

const isShowTokenFrontInstallment = computed(() => {
  return store?.state?.checkout?.isShowTokenFrontInstallment
})

const installmentSelectBin = computed(() => {
  return store?.state?.checkout?.installmentSelectBin
})

const checkout = computed(() => {
  return allCheckout.value?.checkout
})

const cardBinDiscountInfo = computed(() => {
  return allCheckout.value?.cardBinDiscountInfo || {}
})

const defaultSelectTokenPay = computed(() => {
  return allCheckout.value?.defaultSelectTokenPay
})

const iconPrefix = computed(() => {
  return allCheckout.value?.iconPrefix || ''
})

const locals = computed(() => {
  return allCheckout.value?.locals
})

const cardPay = computed(() => {
  return store?.state?.checkout?.status?.cardPay
})

const nowCardBin = computed(() => {
  return props.cardBin
})

const onlinePayDiscountInfoMinAmount = computed(() => {
  return +onlinePayDiscountInfoValue.value?.min?.amount
})

const IS_RW = computed(() => {
  return locals.value?.IS_RW || gbCommonInfo.IS_RW
})

// 在线支付优惠 - 随机立减文案
const randomDiscountDes = computed(() => {
  const onlinePayDiscountValue = onlinePayDiscountInfoValue.value || {}

  if (!+onlinePayDiscountValue?.min?.amount) {
    return template(
      onlinePayDiscountValue?.random_min?.amountWithSymbol,
      onlinePayDiscountValue?.random_max?.amountWithSymbol,
      props.language.SHEIN_KEY_PWA_24866
    )
  }
  return template(
    onlinePayDiscountValue?.random_min?.amountWithSymbol,
    onlinePayDiscountValue?.random_max?.amountWithSymbol,
    onlinePayDiscountValue?.min?.amountWithSymbol,
    props.language.SHEIN_KEY_PWA_24937
  )
})

const paymentIconPrefix = computed(() => {
  return iconPrefix.value ? iconPrefix.value : 'suiiconfont'
})

const isShowCardBinDiscountDesc = computed(() => {
  return isShowCardBinDiscount.value && cardBinDiscountDesc.value
})

const tokenBinDiscountFrontWebAbt = computed(() => {
  const { tokenBinDiscountFrontWeb } = checkout.value?.abtInfo || {}
  return tokenBinDiscountFrontWeb?.param?.ableWeb == 1
})

// abt 是否展示 token 支付
const isShowTokenPaymentByAbt = computed(() => {
  return true  // 11.04已推全
})

const isRouterPayCard = computed(() => {
  return props.item?.code === 'routepay-card'
})

const isInstallmentRouterPayCard = computed(() => {
  return props.item?.code === 'routepay-cardinstallment'
})

const isCanShowPayBinCoupons = computed(() => {
  return isRouterPayCard.value || isInstallmentRouterPayCard.value
})

const isShowTokenPayment = computed(() => {
  // 后端字段是否展示 token 支付
  const isShowTokenPaymentByBackEnd = isRouterPayCard.value && props.item?.shieldAddCard === 0 // 0代表b不屏蔽，则走abt的判断逻辑
  // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
  return (
    isShowTokenPaymentByBackEnd &&
    isShowTokenPaymentByAbt.value &&
    (props.item?.card_token_list || [])?.length > 0
  )
})

const nowShowTokenPayment = computed(() => {
  return (
    isShowTokenPayment.value &&
    (props.item?.card_token_list || [])?.find(
      item => item.id === defaultSelectTokenPay.value?.id
    )
  )
})

const nowInstallmentTokenPayment = computed(() => {
  return (
    isShowTokenFrontInstallment.value &&
    (props.item?.card_token_list || [])?.find(
      item => item.card_bin === installmentSelectBin.value
    )
  )
})

const isShowTokenBinDiscount = computed(() => {
  if (!tokenBinDiscountFrontWebAbt.value) {
    return false
  }

  if (isShowTokenPayment.value && nowShowTokenPayment.value) {
    const { all_card_bin_discount_map = {} } = cardBinDiscountInfo.value
    return nowCardBin.value in all_card_bin_discount_map
  }

  if (isShowTokenFrontInstallment.value && nowInstallmentTokenPayment.value) {
    const { all_card_bin_discount_map = {} } = cardBinDiscountInfo.value
    return nowCardBin.value in all_card_bin_discount_map
  }

  return false
})

const isHadShowOnlinePayDiscount = computed(() => {
  return (
    isShowOnlinePayDiscount.value &&
    onlinePayDiscountInfoValue.value?.discountType != 2
  )
})

const isShowRandomDiscount = computed(() => {
  return (
    onlinePayDiscountInfoValue.value?.discountType == 2 &&
    randomDiscountAbt.value &&
    props.item.enabled
  )
})

const isShowTokenBinDiscountBlock = computed(() => {
  return isShowTokenBinDiscount.value && tokenBinDiscountDesc.value?.length > 0
})

const isPayBinCouponsAdd = computed(() => {
  return (
    isShowTokenBinDiscountBlock.value ||
    isHadShowOnlinePayDiscount.value ||
    isShowRandomDiscount.value
  )
})

// 默认无选中支付方式，选择非卡支付（卡分期或卡路由），对应卡支付方式列表不同时展示在线随机立减和卡bin随机立减文案
const isNoShowTokenBinRandomDiscount = computed(() => {
  if (!isNowTokenBinRandomType.value) return true
  return isNowTokenBinRandomType.value && !isShowRandomDiscountDes.value
})

const isCouponsShowTokenPayment = computed(() => {
  return props?.item?.code !== 'routepay-cardinstallment' ? isShowTokenPayment.value : isShowTokenFrontInstallment.value
})


const showCoBrandAdd = computed(() => {
  // token 卡 bin 优惠
  return (isShowTokenBinDiscountBlock.value && isNoShowTokenBinRandomDiscount.value) && !(isHadShowOnlinePayDiscount.value || isShowRandomDiscount.value) ||
  // 区域:折扣优惠  
  isShowOnlinePayDiscount.value && onlinePayDiscountInfoValue.value && onlinePayDiscountInfoValue.value.discountType != 2 && 
    (
      onlinePayDiscountInfoMinAmount.value != 0 && 
        (
          onlinePayDiscountInfoValue.value.discountType == 0 ||
          onlinePayDiscountInfoValue.value.discountType == 1 ||
          onlinePayDiscountInfoValue.value.discountType == 1 && onlinePayDiscountInfoValue.value.limit_value && +onlinePayDiscountInfoValue.value.limit_value.amount != 0
        ) ||
      onlinePayDiscountInfoMinAmount.value == 0 && onlinePayDiscountInfoValue.value.discountType == 1 && onlinePayDiscountInfoValue.value.limit_value && +onlinePayDiscountInfoValue.value.limit_value.amount != 0
    ) ||
  // 随机立减  
  isShowRandomDiscountDes.value ||
  // 卡bin优惠信息展示    
  !isShowTokenPayment.value && !isShowTokenFrontInstallment.value && isShowCardBinDiscountDesc.value
})

watch(
  () => cardPay.value,
  () => {
    if (typeof window === 'undefined') return
    if (isShowCardBinDiscountDesc.value) {
      setTimeout(() => {
        setRefIsWrapped()
      }, 0)
    }
  },
  {
    immediate: true,
    deep: true
  }
)

watch(
  () => isShowTokenBinDiscount.value,
  () => {
    if (typeof window === 'undefined') return
    if (isShowTokenBinDiscount.value) {
      const { getCardBinDiscountDesc, isTokenBinRandomType } = useTokenBinDiscount(
        cardBinDiscountInfo.value,
        nowCardBin.value,
        props.language
      )
      tokenBinDiscountDesc.value = getCardBinDiscountDesc()
      isNowTokenBinRandomType.value = isTokenBinRandomType
    }
  },
  {
    immediate: true,
    deep: true
  }
)

watch(
  () => nowCardBin.value,
  () => {
    if (typeof window === 'undefined') return
    if (isShowTokenBinDiscount.value) {
      const { getCardBinDiscountDesc, isTokenBinRandomType } = useTokenBinDiscount(
        cardBinDiscountInfo.value,
        nowCardBin.value,
        props.language
      )
      tokenBinDiscountDesc.value = getCardBinDiscountDesc()
      isNowTokenBinRandomType.value = isTokenBinRandomType
    }
  }
)

const handlePaymentOptionsOper = (type = '') => {
  if (!type) return
  emits('payment-options-oper', type)
}

const isWrapped = element => {
  // 如果没有前一个兄弟元素，则认为没有换行
  if (!element?.previousElementSibling) {
    return false
  }
  // 获取当前元素及其前一个兄弟元素的顶部位置
  const currentTop = element?.getBoundingClientRect()?.top
  const siblingTop =
    element?.previousElementSibling?.getBoundingClientRect()?.top

  // 如果当前元素的顶部位置大于前一个兄弟元素的顶部位置，则认为发生了换行
  return currentTop > siblingTop
}

function setRefIsWrapped() {
  if (cardBinDiscountTipRef.value) {
    cardBinDiscountTipRefIsWrapped.value = isWrapped(
      cardBinDiscountTipRef.value
    )
  }

  if (cardBinDiscountDescRef.value) {
    isTextWrapped.value = !hasTextWrapped(cardBinDiscountDescRef.value)
  }
}

function hasTextWrapped(container) {
  // scrollHeight 是元素内容高度的一个度量，包含了由于溢出而不可见的部分。
  // clientHeight 是元素的内部高度，包括 padding ，但不包括水平滚动条、边框和外边距。
  return container.scrollHeight > container.clientHeight
}
</script>

<style scoped lang="less">
.all-discount {
  padding-left: 70/75rem;
  margin-top: 8/75rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .token-bin-block {
    display: flex;

    .add-tip {
      margin: 0 8/75rem;
    }
  }

  .co-branded-card-benefits-wrap {
    display: flex;
    width: 100%;

    .add-tip {
      margin: 0 8/75rem;
    }
  }
}
.discount-percent-sy {
  margin: unset;
}

.discount-tips-item {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: unset;
  display: inline-block;
}

.payment-discount-tip {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tip-wrapped {
  display: inline-block;
  margin-top: 4/75rem;
}

.no-left {
  padding-left: unset;
}

.discount-tips-item {
  margin-bottom: 6/75rem;
}
// .had-token-bin {
//   margin-top: 0.1rem;
// }
</style>
